import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

import '../../generic-page.scss';

const BCMGuidesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_guides.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Guides</h1>
          <h2>Our guides for Black Clover M!</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Newbie zone" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/black-clover/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_about.png"
                alt="Introduction to the game"
              />
            }
          />
          <CategoryCard
            title="Reroll guide"
            link="/black-clover/guides/reroll"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_reroll.png"
                alt="Reroll guide"
              />
            }
          />
          <CategoryCard
            title="Beginner guide"
            link="/black-clover/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_beginner.png"
                alt="Beginner guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Generic guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Basic combat guide"
            link="/black-clover/guides/basic-combat-guide"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_basiccombat.png"
                alt="Basic Combat Guide"
              />
            }
          />
          <CategoryCard
            title="Gear Sets"
            link="/black-clover/guides/gear-sets"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_sets.png"
                alt="Best gear sets"
              />
            }
          />
          <CategoryCard
            title="Best Gear Sets"
            link="/black-clover/guides/best-gear-sets"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_bestsets.png"
                alt="Best gear sets"
              />
            }
          />
          <CategoryCard
            title="Best teams to farm gear"
            link="/black-clover/guides/best-teams-to-farm-gear"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_gear.png"
                alt="Best teams for farm gear"
              />
            }
          />
          <CategoryCard
            title="Great lower rarity Skill Pages"
            link="/black-clover/guides/great-skill-pages"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_skillpages.png"
                alt="Great Skill Pages"
              />
            }
          />
        </Row>
        <SectionHeader title="Other servers" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="How to play on Canada server"
            link="/black-clover/guides/how-to-play-on-canada-server"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_global.png"
                alt="How to play on KR/JPN serves"
              />
            }
          />
          <CategoryCard
            title="How to play on KR/JPN servers"
            link="/black-clover/guides/kr-jpn-servers"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_kr.jpg"
                alt="How to play on KR/JPN serves"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesPage;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Black Clover M | Prydwen Institute"
    description="Guides for Black Clover M gacha game."
  />
);
